import React, { useRef, useState } from 'react';
import { Typography, Row, Col, Carousel } from 'antd';
import { useTranslation } from 'react-i18next';
import KycImage from '../static/assets/kyc 1.png';
import DashboardImage from '../static/assets/monitor 1.png';
import ProduceImage from '../static/assets/wheat 1.png';
import BarCImage from '../static/assets/bar-chart 1.png';
import HelpImage from '../static/assets/help 1.png';
import farmToDoor from '../static/assets/farmTodoor.png';
import priceDiscovery from '../static/assets/priceDiscovery.png';
import bestMatch from '../static/assets/iaInfo.png';
import best from '../static/assets/best.png';
import firstImage from '../static/assets/Rectangle 238.png';
import secondImage from '../static/assets/Rectangle 234.png';
import thirdImage from '../static/assets/Rectangle 235.png';
import fourthImage from '../static/assets/Rectangle 239.png';
import holdingPhone from '../static/assets/image 15.png';
import idea1 from '../static/assets/idea 1.png';
import voice1 from '../static/assets/voice 1.png';
import wheatSign from '../static/assets/wheat.png';
import lineOrange from '../static/assets/Line 5.png';
import path15 from '../static/assets/Path 15.png';
import cement from '../static/assets/cement.png';
import cement1 from '../static/assets/cement (1).png';
import phoneGif from '../static/assets/VB_dashboard_gif.gif';

import { englishStyling, isEnglish, kannadaStyling } from '../static/translations/constants';
import AnimatedComponent from './animationContents/animatedComponents';
import AnimatedKeyComponents from './animationContents/animatedKeyComponents';

const { Text, Title } = Typography;

const Ecosystem = () => {
    const [customClassImage, setCustomClassImage]: any = useState('dashFirstImage');
    const [clickColorBlue, setClickColorBlue] = useState('onClickBlue');
    const { t } = useTranslation('common');
    const customStyles = isEnglish(t('language')) ? englishStyling : kannadaStyling;
    return (
        <div id="ecosystem">
            <Row className="firstRowSolution">
                <Col className="solnFirstCol" xs={24} sm={24} md={24} xl={12} xxl={12}>
                    <div className="topIcon">
                        <img src={idea1} />
                    </div>
                    <Title className="solnTitle">
                        <span>
                            <span>O</span>ur
                        </span>{' '}
                        Solution
                    </Title>
                    <p className="solnText">
                        <span>VikasBandhu</span> is a technology enabled agriculture emarket place
                        with an end-to-end solution helping the farming community to make informed
                        decisions enabled by Farmer as a Service (FAAS) drivers.
                    </p>
                    <Row className="bottomIcons">
                        <img className="wheatSign" src={wheatSign}></img>
                        <img className="lineOrange" src={lineOrange}></img>
                        <img className="path15" src={path15}></img>
                        <img className="lineOrange" src={lineOrange}></img>
                        <img className="cement" src={cement}></img>
                        <img className="cement1" src={cement1}></img>
                    </Row>
                </Col>
                <Col
                    style={{ height: '50rem' }}
                    className="solnSecondCol"
                    xs={24}
                    sm={24}
                    md={24}
                    xl={12}
                    xxl={12}
                >
                    <img
                        style={{ position: 'absolute' }}
                        className="holdingPhone"
                        src={holdingPhone}
                    />
                    <img
                        style={{
                            width: '260px',
                            height: '550px',
                            position: 'relative',
                            left: '125px',
                            top: '60px',
                            transform: 'rotate(-0.5deg)',
                            borderRadius: '15px',
                        }}
                        className="phoneScreen"
                        src={phoneGif}
                    />
                </Col>
            </Row>
            <Row className="secondRowSolution">
                <Row className="keyFirstRow">
                    <Title>Key Performance Indicators</Title>
                    <p className="technologyTxt">Technology Differentiaters of VikasBandhu</p>
                    <AnimatedComponent>
                        <Row justify="space-between" className="keyCardRow">
                            <Col sm={6} xs={6} lg={4} xl={5} xxl={4} className="firstBox">
                                <Row className="titleBox firstTxt">
                                    <div className="firstImage">
                                        <img src={farmToDoor} />
                                    </div>
                                    <Text className="inner-text">Farm - to - Door</Text>
                                </Row>
                                <Row className="cardPoints firstPoints">
                                    <Text>Could First Approach</Text>
                                    <Text>SAAS - Agri Marketplace</Text>
                                    <Text>100% DIgital Payments</Text>
                                    <Text>Involves Transport & Logistics</Text>
                                </Row>
                            </Col>
                            <Col sm={6} xs={6} lg={4} xl={5} xxl={4} className="secondBox">
                                <Row className="titleBox secondTxt">
                                    <div className="firstImage">
                                        <img src={priceDiscovery} />
                                    </div>
                                    <Text className="inner-text">Price Discovery</Text>
                                </Row>
                                <Row className="cardPoints secondPoints">
                                    <Text>
                                        Provides the price reference enabled with Trends and
                                        Predictions
                                    </Text>
                                    <Text>Driven by Big Data and AI</Text>
                                    <Text>Enables Sell, Hold decisions</Text>
                                </Row>
                            </Col>
                            <Col sm={6} xs={6} lg={4} xl={5} xxl={4} className="thirdBox">
                                <Row className="titleBox thirdTxt">
                                    <div className="firstImage">
                                        <img src={bestMatch} />
                                    </div>
                                    <Text className="inner-text">Best Match</Text>
                                </Row>
                                <Row className="cardPoints thirdPoints">
                                    <Text>Proprietary Rank Algorithm</Text>
                                    <Text>
                                        Smart System connects right seller with buyer automatically
                                    </Text>
                                    <Text>Enables a Better Coonection to Transaction Ration</Text>
                                </Row>
                            </Col>
                            <Col sm={6} xs={6} lg={4} xl={5} xxl={4} className="fourthBox">
                                <Row className="titleBox fourthTxt">
                                    <div className="firstImage">
                                        <img src={best} />
                                    </div>
                                    <Text className="inner-text">{t('ecosystem_page.text44')}</Text>
                                </Row>
                                <Row className="cardPoints fourthPoints">
                                    <Text>{t('ecosystem_page.text45')}</Text>
                                    <Text>{t('ecosystem_page.text46')}</Text>
                                    <Text>{t('ecosystem_page.text47')}</Text>
                                    <Text>{t('ecosystem_page.text48')}</Text>
                                    <Text>{t('ecosystem_page.text49')}</Text>
                                </Row>
                            </Col>
                        </Row>
                    </AnimatedComponent>
                    {/* only for mobile view */}
                    <Row className="Phone-view">
                        <Col span={24} xs={24} md={24}>
                            <Carousel autoplay dots={false} fade>
                                <div>
                                    <Col xl={6} xxl={6} className="firstBox">
                                        <Row className="titleBox firstTxt">
                                            <div className="firstImage">
                                                <img src={farmToDoor} />
                                            </div>
                                            <Text className="inner-text">Farm - to - Door</Text>
                                        </Row>
                                        <Row className="cardPoints firstPoints">
                                            <Text>Could First Approach</Text>
                                            <Text>SAAS - Agri Marketplace</Text>
                                            <Text>100% DIgital Payments</Text>
                                            <Text>Involves Transport & Logistics</Text>
                                        </Row>
                                    </Col>
                                </div>
                                <div>
                                    <Col xl={6} xxl={6} className="secondBox">
                                        <Row className="titleBox secondTxt">
                                            <div className="firstImage">
                                                <img src={priceDiscovery} />
                                            </div>
                                            <Text className="inner-text">Price Discovery</Text>
                                        </Row>
                                        <Row className="cardPoints secondPoints">
                                            <Text>
                                                Provides the price reference enabled with Trends and
                                                Predictions
                                            </Text>
                                            <Text>Driven by Big Data and AI</Text>
                                            <Text>Enables Sell, Hold decisions</Text>
                                        </Row>
                                    </Col>
                                </div>
                                <div>
                                    <Col xl={6} xxl={6} className="thirdBox">
                                        <Row className="titleBox thirdTxt">
                                            <div className="firstImage">
                                                <img src={bestMatch} />
                                            </div>
                                            <Text className="inner-text">Best Match</Text>
                                        </Row>
                                        <Row className="cardPoints thirdPoints">
                                            <Text>Proprietary Rank Algorithm</Text>
                                            <Text>
                                                Smart System connects right seller with buyer
                                                automatically
                                            </Text>
                                            <Text>
                                                Enables a Better Coonection to Transaction Ration
                                            </Text>
                                        </Row>
                                    </Col>
                                </div>
                                <div>
                                    <Col xl={6} xxl={6} className="fourthBox">
                                        <Row className="titleBox fourthTxt">
                                            <div className="firstImage">
                                                <img src={best} />
                                            </div>
                                            <Text className="inner-text">
                                                {t('ecosystem_page.text44')}
                                            </Text>
                                        </Row>
                                        <Row className="cardPoints fourthPoints">
                                            <Text>{t('ecosystem_page.text45')}</Text>
                                            <Text>{t('ecosystem_page.text47')}</Text>
                                            <Text>{t('ecosystem_page.text48')}</Text>
                                            <Text>{t('ecosystem_page.text49')}</Text>
                                        </Row>
                                    </Col>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </Row>

                <Row className="keySecondRow">
                    <Row className="keyBoxContent">
                        <Col sm={24} xs={24} md={24} lg={24} xl={8} xxl={8} className="keyBoxImage">
                            <img src={voice1} />
                        </Col>
                        <Col
                            sm={24}
                            xs={24}
                            md={24}
                            lg={24}
                            xl={16}
                            xxl={16}
                            className="keyBoxTxtCont"
                        >
                            <Text className="keyBoxTxt">
                                Voice Enabled Solutions - Voice enabled commerce will help the rural
                                producers to transact without the need of the conventional User
                                Interface . This will help the local/rural Producers/consumers who
                                may not be conversant with the Rich/Heavy UI based application
                                interface. Sell /Hold Decisions - It allows Futuristic Prediction to
                                help us provide HOLD/SELL decisions to producers ( driven by Big
                                Data ,AI).The Hold decisions to gain benefit are enabled as a full
                                solution with means to store in warehouses (using Block chain).
                            </Text>
                        </Col>
                    </Row>
                </Row>
            </Row>

            <Row className="thirdRowSolution">
                <Row className="dashTitle">
                    <Text className="vkTitle">VikasBandhu</Text>
                    <Text className="vkTitle1">Dashboard</Text>
                </Row>
                <AnimatedComponent>
                    <Row className="dashBoardRow">
                        <Col xs={24} sm={24} md={12} lg={32} className="dashFirstCol">
                            <div className="dashBtnContainer">
                                <div
                                    className={
                                        customClassImage === 'dashFirstImage'
                                            ? `dashBoardBtn ${clickColorBlue}`
                                            : 'dashBoardBtn'
                                    }
                                    onClick={() => {
                                        setCustomClassImage('dashFirstImage');
                                        setClickColorBlue('onClickBlue');
                                    }}
                                >
                                    <Text>
                                        Seller and Buyers register with VikasBandhu by providing
                                        basic information and documents
                                    </Text>
                                </div>
                                <div
                                    className={
                                        customClassImage === 'dashSecondImage'
                                            ? `dashBoardBtn ${clickColorBlue}`
                                            : 'dashBoardBtn'
                                    }
                                    onClick={() => {
                                        setCustomClassImage('dashSecondImage');
                                        setClickColorBlue('onClickBlue');
                                    }}
                                >
                                    <Text>
                                        After verification, you can add produce to sell or
                                        requirement to buy
                                    </Text>
                                </div>
                                <div
                                    className={
                                        customClassImage === 'dashThirdImage'
                                            ? `dashBoardBtn ${clickColorBlue}`
                                            : 'dashBoardBtn'
                                    }
                                    onClick={() => {
                                        setCustomClassImage('dashThirdImage');
                                        setClickColorBlue('onClickBlue');
                                    }}
                                >
                                    <Text>
                                        Get best match from our best VikasBandhu matching system
                                        powered by AI
                                    </Text>
                                </div>
                                <div
                                    className={
                                        customClassImage === 'dashFourthImage'
                                            ? `dashBoardBtn ${clickColorBlue}`
                                            : 'dashBoardBtn'
                                    }
                                    onClick={() => {
                                        setCustomClassImage('dashFourthImage');
                                        setClickColorBlue('onClickBlue');
                                    }}
                                >
                                    <Text>
                                        Start your trade and track full transaction with full
                                        transparency
                                    </Text>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={32} className="dashSecondCol">
                            <div className={`dashBoardImage ${customClassImage}`}></div>
                        </Col>
                    </Row>
                </AnimatedComponent>
            </Row>

            <Row className="fourthRowSolution">
                <Text className="keyTitle">Key Features</Text>
                <Row className="keyFeatureBadges">
                    <AnimatedKeyComponents>
                        <div className="keyBadges">
                            <img src={KycImage} />
                            <Text className="keyCardTitle">Simple KYC process</Text>
                            <Text className="keyCardTxt">
                                Easy and guided KYC process to verify account
                            </Text>
                        </div>
                    </AnimatedKeyComponents>
                    <AnimatedKeyComponents>
                        <div className="keyBadges">
                            <img src={DashboardImage} />
                            <Text className="keyCardTitle">Dashboard</Text>
                            <Text className="keyCardTxt">
                                View all your produce, matches and transaction on user friendly
                                dashboard
                            </Text>
                        </div>
                    </AnimatedKeyComponents>
                    <AnimatedKeyComponents>
                        <div className="keyBadges">
                            <img src={ProduceImage} />
                            <Text className="keyCardTitle">Produce</Text>
                            <Text className="keyCardTxt">
                                We support wide verity of produce category
                            </Text>
                        </div>
                    </AnimatedKeyComponents>
                    <AnimatedKeyComponents>
                        <div className="keyBadges">
                            <img src={BarCImage} />
                            <Text className="keyCardTitle">Pricing Guide</Text>
                            <Text className="keyCardTxt">
                                Get information of latest market price for your produce
                            </Text>
                        </div>
                    </AnimatedKeyComponents>
                    <AnimatedKeyComponents>
                        <div className="keyBadges">
                            <img src={HelpImage} />
                            <Text className="keyCardTitle">Support</Text>
                            <Text className="keyCardTxt">
                                Reachout to your local expert for any help
                            </Text>
                        </div>
                    </AnimatedKeyComponents>
                </Row>

                <div className="keyBadgesPhone">
                    <Carousel autoplay dots={false} fade>
                        <div>
                            <div className="keyBadges">
                                <img src={KycImage} />
                                <Text className="keyCardTitle">Simple KYC process</Text>
                                <Text className="keyCardTxt">
                                    Easy and guided KYC process to verify account
                                </Text>
                            </div>
                        </div>
                        <div>
                            <div className="keyBadges">
                                <img src={DashboardImage} />
                                <Text className="keyCardTitle">Dashboard</Text>
                                <Text className="keyCardTxt">
                                    View all your produce, matches and transaction on user friendly
                                    dashboard
                                </Text>
                            </div>
                        </div>
                        <div>
                            <div className="keyBadges">
                                <img src={ProduceImage} />
                                <Text className="keyCardTitle">Produce</Text>
                                <Text className="keyCardTxt">
                                    We support wide verity of produce category
                                </Text>
                            </div>
                        </div>
                        <div>
                            <div className="keyBadges">
                                <img src={BarCImage} />
                                <Text className="keyCardTitle">Pricing Guide</Text>
                                <Text className="keyCardTxt">
                                    Get information of latest market price for your produce
                                </Text>
                            </div>
                        </div>
                        <div>
                            <div className="keyBadges">
                                <img src={HelpImage} />
                                <Text className="keyCardTitle">Support</Text>
                                <Text className="keyCardTxt">
                                    Reachout to your local expert for any help
                                </Text>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <Text className="keyAddition">In addition</Text>
                <Row className="keyFeatureBottomRow">
                    <Col sm={24} xs={24} md={24} lg={6} className="keyRows">
                        <div>
                            <p className="keyMainTitle">We provide</p>
                            <p className="keyMainTitle1">Expert Help</p>
                        </div>
                        <p className="keySubTxt">
                            Help from local agro experts to help during trade
                        </p>
                    </Col>
                    <div className="line"></div>
                    <Col sm={24} xs={24} md={24} lg={6} className="keyRows">
                        <div>
                            <p className="keyMainTitle">We provide </p>
                            <p className="keyMainTitle1">Local Employment</p>
                        </div>
                        <p className="keySubTxt">
                            Boost rural economy by providing local employment.
                        </p>
                    </Col>
                    <div className="line"></div>
                    <Col sm={24} xs={24} md={24} lg={6} className="keyRows">
                        <div>
                            <p className="keyMainTitle">We collaborate with </p>
                            <p className="keyMainTitle1">Government</p>
                        </div>
                        <p className="keySubTxt">Partnering with Government's vision</p>
                    </Col>
                </Row>
            </Row>
            <Row className="bestPractices">
                <AnimatedKeyComponents>
                    <Col className="bestImage"></Col>
                </AnimatedKeyComponents>
                <AnimatedComponent>
                    <Col className="bestSolnTxtContainer">
                        <Title className="bestSolnTitle">Our Best Solution Practices</Title>
                        <p className="bestSolnTxt">
                            <span>Cloud first</span> based application running on AWS (Amazon Web
                            Services) Driven by
                            <span>Market research</span> for 6 months and <span>UX design</span> to
                            ensure that the application was optimized and easy to use Front End UI
                            application development is enabled by the <span>latest frameworks</span>{' '}
                            (React /Redux) Backend is enabled with
                            <span>microservices/serverless framework</span> powered by Node
                            Js/Python <span>Test Automation</span>
                            driven by Selenium (for Browser ) and Appium ( for Android)
                        </p>
                    </Col>
                </AnimatedComponent>
            </Row>
        </div>
    );
};

export default Ecosystem;
