import React from 'react';
import { Typography, Button, Carousel, Modal, Breadcrumb, Row, Col } from 'antd';
import { History } from 'history';
import { useTranslation } from 'react-i18next';
import { isEnglish } from '../static/translations/constants';
import Register from '../login-ui/register';
import googlePlay from '../static/assets/googleimage.png';
import mainImage from '../static/assets/mainImage.png';
import mainImagewebp from '../static/assets/mainImage.webp';
import firstIcon from '../static/assets/image 13.png';
import secondIcon from '../static/assets/image 12.png';
import thirdIcon from '../static/assets/image 11.png';
import fourthIcon from '../static/assets/nintyTons.jpeg';
import AnimatedComponent from './animationContents/animatedComponents';
import AnimatedKeyComponents from './animationContents/animatedKeyComponents';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';
import AnimatedHomeOne from './animationContents/animatedHome';

const { Title, Text } = Typography;

const Home = ({
    history,
    popUpTrigger,
    setSignUpPopupVisible,
}: {
    history: History;
    popUpTrigger: any;
    setSignUpPopupVisible: Function;
}) => {
    const { signUpPopupVisible } = popUpTrigger;
    const { t } = useTranslation('common');
    const isEnglishTrans = isEnglish(t('language'));

    const AnimatedHomeTwo = ({ children }: any) => {
        const [inViewRef, inView] = useInView({
            triggerOnce: true,
        });

        const animationProps = useSpring({
            from: { opacity: 0, transform: 'translateX(100px)' },
            to: {
                opacity: inView ? 1 : 0,
                transform: inView ? 'translateX(0)' : 'translateX(100px)',
            },
            config: { duration: 2000 },
        });

        return (
            <animated.div ref={inViewRef} style={animationProps}>
                {children}
            </animated.div>
        );
    };

    return (
        <div id="home">
            <Row className="home-wrapper-row">
                <Col xs={24} sm={24} md={24} xl={12} xxl={12} className="pictureWrapper">
                    <AnimatedHomeOne>
                        <picture>
                            <source srcSet={mainImagewebp} type="image/webp" />
                            <source srcSet={mainImage} type="image/png" />
                            <img src={mainImage} alt="mainImage" />
                        </picture>
                    </AnimatedHomeOne>
                </Col>
                <Col xs={24} sm={24} md={24} xl={12} xxl={12} className="secondColumn">
                    <AnimatedHomeTwo>
                        <div>
                            <Row className="mainTextWrapper">
                                <Title
                                    className="homeTitle"
                                    level={1}
                                    //className={isEnglishTrans ? 'homeTitle' : 'homeTitleKan'}
                                >
                                    Agri<span>.</span>
                                </Title>
                                <Title
                                    className="homeTitle1"
                                    level={1}
                                    //className={isEnglishTrans ? 'homeTitle' : 'homeTitleKan'}
                                >
                                    Sell.Buy.
                                </Title>
                                <p>A friendly digital E-market place for agricultural produce</p>
                            </Row>
                            <div className="registerComponent">
                                <Button onClick={() => setSignUpPopupVisible(!signUpPopupVisible)}>
                                    Register today!
                                </Button>
                                <Modal
                                    title={null}
                                    open={signUpPopupVisible}
                                    footer={null}
                                    maskClosable={false}
                                    className="custom-register-modal"
                                    onCancel={() => setSignUpPopupVisible(!signUpPopupVisible)}
                                    centered
                                    wrapClassName="register-popup-container"
                                >
                                    <Register
                                        history={history}
                                        setSignUpPopupVisible={setSignUpPopupVisible}
                                    />
                                </Modal>
                                <p>And be part of VikanBandhu family</p>
                            </div>
                            <Button
                                className="googlePlay"
                                size="small"
                                type="link"
                                href="https://play.google.com/store/apps/details?id=com.vikasBandhu"
                                target="_blank"
                            >
                                <img className="androidImage" src={googlePlay} />
                            </Button>
                        </div>
                    </AnimatedHomeTwo>
                </Col>
            </Row>
            <Row className="secondRowInHome">
                <AnimatedComponent>
                    <Row className="floatingCard">
                        <Col className="cardContent">
                            <Title className="vbTitle">VikasBandhu</Title>
                            <Text className="vbText">
                                VikasBandhu is a technology enabled agriculture free e-market place
                                for a willing buyer and seller to make informed decisions. It is
                                aimed to help the buyers and sellers (Farmer self-help groups) to
                                connect, engage and transact for win-win relationships
                            </Text>
                            <Row className="cardIcons">
                                <Col xs={24} sm={24} md={24} lg={6} className="first">
                                    <img src={firstIcon} />
                                    <Text className="mainText">100+</Text>
                                    <Text className="subText">Users reached</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} className="second">
                                    <img src={secondIcon} />
                                    <Text className="mainText">₹3cr+</Text>
                                    <Text className="subText">Transactions</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} className="third">
                                    <img src={thirdIcon} />
                                    <Text className="mainText">10+</Text>
                                    <Text className="subText">Districts</Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} className="third">
                                    <img src={fourthIcon} />
                                    <Text className="mainText">90+</Text>
                                    <Text className="subText">Tons</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AnimatedComponent>
            </Row>
        </div>
    );
};

export default Home;
