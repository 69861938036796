import React from 'react';


import Aim from './aim';
import Ecosystem from './ecosystem';
import Commodities from './commodities';
// import Founders from './founders';
// import Partners from './partners';
import Legal from './legal';
import ContactUs from './contactUs';
import Team from './team';
// import Updates from './updates';
import './landingPage.scss';
import Footer from '../footer';
import AnimatedComponent from './animationContents/animatedComponents';



const LandingPage = () => {
    return (
        <React.Fragment>
                <Aim />
            <AnimatedComponent>
                <Ecosystem />
            </AnimatedComponent>
                <Commodities />
            <AnimatedComponent>
                {/* <Founders /> */}
            </AnimatedComponent>
            <AnimatedComponent>
                {/* <Partners /> */}
            </AnimatedComponent>
        </React.Fragment>
    );
};

export default LandingPage;
